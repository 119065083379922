<template>
  <div>
    <div class="title">
      <div style="font-size: 30px">Registrar solicitud</div>
    </div>

    <div class="father-text lighten-4">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12">
          <div outlined tile class="pa-10 question">
            Antes de crear un producto, registra la solicitud
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-card class="mycard">
            <div class="text-question">
              <v-text-field
                v-model="asunto"
                :counter="1000"
                label="Asunto"
                required
                @input="$v.asunto.$touch()"
                @blur="$v.asunto.$touch()"
              ></v-text-field>
            </div>

            <v-file-input
              show-size
              counter
              multiple
              label="Adjuntar documento"
              v-model="file"
            >
            </v-file-input>

            <div class="container-button">
              <v-btn class="button" color="primary" @click="submit">
                Guardar Solicitud
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    asunto: { required },
  },

  data: () => ({
    asunto: "",
    file: [],
    base64: "",
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 100,
      headers: { "My-Awesome-Header": "header value" },
    },
  }),

  methods: {
    submit() {
      console.log(this.asunto);
      console.log(this.file);
      let archivo = this.file[0];
      console.log(archivo);

      let reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = () => {
        console.log(reader.result);
      };
    },
  },
};
</script>

<style scoped>
.title {
  color: black;
  margin-top: 30px;
  font-size: 12em;
  margin-left: 1em;
  font-weight: bold;
}
.question {
  font-weight: bold;
  text-align: center;
  font-size: 28px;
}

.text-question {
  margin-top: 1em;
}

.container-button {
  margin-top: 20px;
  padding-left: 30%;
  padding-right: 30%;
}

.button {
  width: 100%;
  border-radius: 10px;
}

.father-text {
  height: 35em;
  border: 2px solid rgba(192, 192, 192, 0.3);
  margin-top: 2em;
  margin-left: 120px;
  margin-right: 120px;
  padding-left: 70px;
  padding-right: 70px;
  background-color: rgba(192, 192, 192, 0.1);
}

.mycard {
  height: 20em;
  padding: 20px;
}

@media (max-width: 900px) {
  .father-text {
    height: 30em;
    border: 1px solid rgba(192, 192, 192, 0.3);
    margin-left: 80px;
    margin-right: 80px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .text-question {
    margin-top: 1em;
  }
}

@media (max-width: 600px) {
  .father-text {
    height: 30em;
    border: 1px solid rgba(192, 192, 192, 0.3);
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .text-question {
    margin-top: 2em;
  }
}
</style>
